<template>
  <div>
    <div class="separator mt-6">
      <div
        class="label"
      >
        <span>Informações do processo</span>
      </div>
    </div>

    <div
      class="progress-container"
    >
      <span>Status do Processo</span>

      <div class="container-icon">
        <img
          src="../../../../../../assets/rightIcon.svg"
          width="20"
          alt="right-icon"
        >

        <small class="status-concluido">CONCLUÍDO</small>
      </div>

      <div class="item-time-line-first">
        <p>REQUISIÇÃO REALIZADA</p>

        <p>{{ dateFormat(data.created_at) }}</p>
      </div>

      <div
        v-for="(history, index) in processedStatus"
        :key="history.id"
        class="container-item"
        :style="index === processedStatus.length - 1 ? 'margin-top: 0px' : ''"
      >
        <div class="container-icon">
          <img
            v-if="index !== processedStatus.length - 1 || hasConcluded"
            src="../../../../../../assets/rightIcon.svg"
            width="20"
            alt="right-icon"
          >

          <img
            v-else-if="history.status === 'CANCELADO'"
            src="../../../../../../assets/cancelIcon.svg"
            width="17"
            alt="waiting-icon"
          >

          <img
            v-else-if="history.status === 'CONCLUIDO'"
            src="../../../../../../assets/rightIcon.svg"
            width="17"
            alt="right-icon"
          >

          <img
            v-else
            src="../../../../../../assets/waitingIcon.svg"
            width="17"
            alt="waiting-icon"
          >

          <small :class="getStatusClass(history)">{{ getStatusTitle(history) }}</small>
        </div>

        <div :class="['item-time-line', {'no-border': index === processedStatus.length - 1}]">
          <p>{{ history.status }} </p>

          <p>{{ dateFormat(history.updated_at) }}</p>
        </div>
      </div>
    </div>

    <div
      v-if="showInformationField"
      class="separator  my-8"
    >
      <div
        class="label"
      >
        <span>Informações da Rescisão</span>
      </div>
    </div>

    <v-form
      v-if="showDocumentFields || ['AGUARDANDO CONCLUSÃO', 'CONCLUIDO'].includes(data.status)"
      ref="form"
    >
      <span>Enviar documentação de aviso para o líder?</span>

      <v-radio-group
        v-model="enableFileUpload"
        mandatory
        :disabled="['AGUARDANDO CONCLUSÃO', 'CONCLUIDO'].includes(data.status)"
        row
      >
        <v-radio
          label="Sim"
          :value="true"
          color="#9C43FA"
        />

        <v-radio
          label="Não"
          :value="false"
          color="#9C43FA"
        />
      </v-radio-group>

      <div
        class="d-flex"
        style="gap: 10px; align-items: flex-end;"
      >
        <div style="width: 60%;">
          <v-file-input
            v-model="file"
            class="mt-1"
            label="Inserir Documento"
            outlined
            dense
            chips
            :append-icon="icons.mdiPaperclip"
            :prepend-icon="null"
            :disabled="!enableFileUpload"
            @click:clear="clearFileField()"
          />
        </div>

        <v-text-field
          v-if="['AGUARDANDO CONCLUSÃO', 'CONCLUIDO'].includes(data.status)"
          v-model="formatLastWorkedDate"
          label="Ultimo dia trabalhado"
          :prepend-inner-icon="icons.mdiCalendar"
          readonly
          outlined
          dense
        />

        <div v-else>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="isoDate"
            transition="scale-transition"
            min-width="auto"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedDate"
                label="Ultimo dia trabalhado"
                :prepend-inner-icon="icons.mdiCalendar"
                :disabled="enableFileUpload"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-if="menu"
              v-model="isoDate"
              locale="pt-BR"
              @change="(date) => ($refs.menu.save(date), (isoDate = date))"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>

      <div
        v-if="showLeaderDocumentField"
        class="d-flex"
        style="width: 100%; gap: 10px;"
      >
        <v-img
          :src="icons.cloudArrowUpOutline"
          alt="My Icon"
          max-width="20"
          max-height="20"
        />

        <p>
          <strong style="color: rgba(239, 112, 255, 1)">
            Documento de aviso
          </strong>

          <strong style="color: rgba(239, 112, 255, 1)">
            enviado
          </strong>

          pelo líder, assinado e escaneado.
        </p>
      </div>

      <div
        v-if="showLeaderDocumentField"
        class="mb-6"
      >
        <span class="document-label px-2 py-0">
          Documento
        </span>

        <div
          class="container-files"
        >
          <v-chip
            class="ml-2"
            :disabled="isLoadingFile"
            @click="getFile(leaderFile)"
          >
            <v-progress-circular
              v-if="isLoadingFile"
              color="white"
              size="25"
              indeterminate
            />

            <span v-else>
              {{ leaderFile.name }}
            </span>
          </v-chip>

          <v-icon>{{ icons.mdiPaperclip }}</v-icon>
        </div>
      </div>
    </v-form>

    <div v-if="data.status === 'AGUARDANDO LÍDER'">
      <div
        class="d-flex"
        style="width: 100%; gap: 10px;"
      >
        <v-img
          :src="icons.cloudArrowDownOutline"
          alt="My Icon"
          max-width="20"
          max-height="20"
        />

        <p>
          <strong style="color: rgba(239, 112, 255, 1)">
            Baixar
          </strong>

          documento de aviso

          <strong style="color: rgba(239, 112, 255, 1)">
            enviado
          </strong>

          pelo DP para assinatura.
        </p>
      </div>

      <div
        class="mb-6"
      >
        <span class="document-label px-2 py-0">
          Documento de aviso
        </span>

        <div
          class="container-files"
        >
          <v-chip
            class="ml-2"
            :disabled="isLoadingFile"
            @click="getFile(personnelDepartmentFile)"
          >
            <v-progress-circular
              v-if="isLoadingFile"
              color="white"
              size="25"
              indeterminate
            />

            <span v-else>
              {{ personnelDepartmentFile.name }}
            </span>
          </v-chip>

          <v-icon>{{ icons.mdiDownload }}</v-icon>
        </div>
      </div>

      <div v-if="data.status === 'AGUARDANDO LÍDER'">
        <div
          class="d-flex mb-2"
          style="width: 100%; gap: 10px;"
        >
          <v-img
            :src="icons.cloudArrowUpOutline"
            alt="My Icon"
            max-width="20"
            max-height="20"
          />

          <p>
            <strong style="color: rgba(239, 112, 255, 1)">
              Devolver
            </strong>

            documento de aviso

            <strong style="color: rgba(239, 112, 255, 1)">
              assinado
            </strong>

            e

            <strong style="color: rgba(239, 112, 255, 1)">
              escaneado
            </strong>

            para o DP.
          </p>
        </div>

        <v-file-input
          v-model="file"
          :append-icon="icons.mdiPaperclip"
          :prepend-icon="null"
          label="Inserir Documento"
          clearable
          outlined
          dense
        />
      </div>
    </div>

    <div style="width: 30%">
      <v-text-field
        v-if="showOnlyLastWorkedDate"
        v-model="formatLastWorkedDate"
        label="Último dia trabalhado"
        :prepend-inner-icon="icons.mdiCalendar"
        readonly
        outlined
        dense
      />
    </div>

    <div>
      <v-textarea
        v-if="data.status === 'CANCELADO'"
        v-model="data.cancellation_reason"
        class="mt-6"
        label="Motivo do Cancelamento"
        outlined
        readonly
      />
    </div>

    <div class="d-flex align-center justify-space-between mt-4">
      <v-btn
        outlined
        @click="previousTab()"
      >
        <div
          class="d-flex align-center"
          style="gap: 10px;"
        >
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
          <span>
            Anterior
          </span>
        </div>
      </v-btn>

      <v-btn
        v-if="data.status === 'AGUARDANDO LÍDER'"
        color="success"
        :disabled="isLoadingSendData"
        @click="sendModalData()"
      >
        <div
          v-if="!isLoadingSendData"
          class="d-flex"
          style="gap: 5px;"
        >
          <v-img
            :src="icons.arrowRight"
            width="20"
            max-height="20"
            class="me-2"
          />

          <span>
            Enviar para o DP
          </span>
        </div>

        <v-progress-circular
          v-else
          color="white"
          indeterminate
        />
      </v-btn>

      <div
        v-if="showButtonReturnToLeader || showButtonSend"
        class="d-flex"
        style="gap: 10px;"
      >
        <v-btn
          v-if="showButtonReturnToLeader"
          color="orange"
          :disabled="isLoadingSendLeader"
          @click="returnToLeader()"
        >
          <div
            v-if="!isLoadingSendLeader"
            class="d-flex"
            style="gap: 5px;"
          >
            <v-img
              :src="icons.arrowLeftDown"
              width="20"
              max-height="20"
              class="me-2"
            />

            <span style="color: white;">
              Retornar Líder
            </span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          />
        </v-btn>

        <v-btn
          v-if="showButtonSend"
          color="success"
          :disabled="isLoadingSendData"
          @click="sendModalData()"
        >
          <div
            v-if="!isLoadingSendData"
            class="d-flex"
            style="gap: 5px;"
          >
            <v-img
              :src="icons.arrowRight"
              width="20"
              max-height="20"
              class="me-2"
            />

            <span>
              Enviar
            </span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          />
        </v-btn>
      </div>

      <div v-if="data.status === 'AGUARDANDO CONCLUSÃO' && isFromPersonalDepartment">
        <v-btn
          color="success"
          style="width: fit-content;"
          @click="sendRequestToFinalize()"
        >
          <div
            v-if="!isLoadingFinalize"
            class="d-flex align-center"
            style="gap: 10px;"
          >
            <v-icon>{{ icons.mdiCheck }}</v-icon>

            <span>
              Concluir
            </span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'

import {
  mdiArrowLeft, mdiCalendar,
  mdiCheck,
  mdiDownload,
  mdiPaperclip,
} from '@mdi/js'

import arrowLeftDown from '@/assets/images/svg/arrow-left-down.svg'
import arrowRight from '@/assets/images/svg/arrow-right.svg'
import cloudArrowDownOutline from '@/assets/images/svg/cloud-arrow-down-outline.svg'
import cloudArrowUpOutline from '@/assets/images/svg/cloud-arrow-up-outline.svg'
import saveAs from 'file-saver'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    data: {
      type: Object,
      default: () => ({ status: '' }),
    },

    userProfile: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      file: null,
      menu: false,
      isoDate: '',

      enableFileUpload: false,
      hasConcluded: false,
      isLoadingFile: false,
      isLoadingSendData: false,
      isLoadingFinalize: false,
      isLoadingSendLeader: false,

      isFromHumanResources: false,
      isFromPersonalDepartment: false,

      icons: {
        mdiCheck,
        arrowRight,
        mdiCalendar,
        mdiDownload,
        mdiPaperclip,
        mdiArrowLeft,
        arrowLeftDown,
        cloudArrowUpOutline,
        cloudArrowDownOutline,
      },
    }
  },

  computed: {
    showInformationField() {
      const hasCancelledStatus = this.processedStatus.some(({ status }) => status === 'CANCELADO')
      const hasLeaderStatus = this.processedStatus.some(({ status }) => status === 'AGUARDANDO LÍDER')
      const hasDpStatus = this.processedStatus.some(({ status }) => status === 'AGUARDANDO DP')

      return !hasCancelledStatus || (!hasLeaderStatus && hasDpStatus)
    },

    showDocumentFields() {
      const hasLeaderStatus = this.processedStatus.some(({ status }) => status === 'AGUARDANDO LÍDER')
      const waitingDP = this.data.status === 'FINALIZAÇÃO COM DP'

      if (hasLeaderStatus && waitingDP && this.isFromPersonalDepartment) return true

      return (!hasLeaderStatus && waitingDP) && this.isFromPersonalDepartment
    },

    showLeaderDocumentField() {
      const hasLeaderStatus = this.processedStatus.some(({ status }) => status === 'AGUARDANDO LÍDER')
      const waitingDp = this.data.status === 'FINALIZAÇÃO COM DP'
      const waitingFinish = this.data.status === 'AGUARDANDO CONCLUSÃO'
      const finishedStatus = this.data.status === 'CONCLUIDO'

      return hasLeaderStatus && (waitingDp || waitingFinish || finishedStatus)
    },

    showDpDocumentField() {
      const hasLeaderStatus = this.processedStatus.some(({ status }) => status === 'AGUARDANDO LÍDER')

      return hasLeaderStatus
    },

    formatLastWorkedDate() {
      if (!this.data.Last_day_worked) return ''

      const [year, month, day] = this.data.Last_day_worked.split('-').map(Number)

      const date = new Date(year, month - 1, day)

      return date.toLocaleDateString('pt-BR')
    },

    showOnlyLastWorkedDate() {
      const hasLeaderStatus = this.processedStatus.some(({ status }) => status === 'AGUARDANDO LÍDER')

      if (!this.formatLastWorkedDate) return false

      return !hasLeaderStatus
    },

    showButtonSend() {
      const waitingDP = this.data.status === 'FINALIZAÇÃO COM DP'

      return waitingDP && this.isFromPersonalDepartment
    },

    showButtonReturnToLeader() {
      const hasLeaderStatus = this.processedStatus.some(({ status }) => status === 'AGUARDANDO LÍDER')
      const waitingDP = this.data.status === 'FINALIZAÇÃO COM DP'

      return hasLeaderStatus && waitingDP && this.isFromPersonalDepartment
    },

    processedStatus() {
      const rawData = this.data.status_update_shutdowns
      const processedStatus = rawData.map((element, index) => ({
        ...element,
        description: index === 0 ? 'REQUISIÇÃO REALIZADA' : element.status,
        formattedDate: new Date(element.created_at).toLocaleString(),
        isFinal: index === rawData.length - 1,
      }))

      return processedStatus
    },

    personnelDepartmentFile() {
      const regex = /departamento\s+pessoal/i
      const documents = this.data.attachments_shutdowns_dp

      const filteredDocuments = documents.filter(document => {
        const profileField = document.user?.profile?.profile

        if (profileField === undefined) {
          console.error('Profile field is undefined:', document)

          return false
        }

        const matches = regex.test(profileField)

        return matches
      })

      const personnelDepartmentFile = filteredDocuments?.pop()

      return personnelDepartmentFile ?? { id: 'some-id', name: 'document' }
    },

    leaderFile() {
      const regex = /departamento\s+pessoal/i
      const documents = this.data.attachments_shutdowns_dp

      const filteredDocuments = documents.filter(document => {
        const profileField = document.user?.profile?.profile

        if (profileField === undefined) {
          console.error('Profile field is undefined:', document)

          return false
        }

        const matches = !regex.test(profileField)

        return matches
      })

      const personnelDepartmentFile = filteredDocuments?.pop()

      return personnelDepartmentFile ?? { id: 'some-id', name: 'document' }
    },

    hasWaitingLeaderStatus() {
      const isWaitingLeader = this.processedStatus.some(({ status }) => status === 'AGUARDANDO LÍDER')

      return isWaitingLeader
    },

    formattedDate: {
      get() {
        if (!this.isoDate) return ''

        const [year, month, day] = this.isoDate.split('-')

        return `${day}/${month}/${year}`
      },

      set(value) {
        if (!value) {
          this.isoDate = value

          return
        }

        const [day, month, year] = value.split('/')
        this.isoDate = `${year}-${month}-${day}`
      },
    },
  },

  watch: {
    enableFileUpload(value) {
      if (value) {
        this.isoDate = ''
      }

      if (!value) {
        this.file = null
      }
    },
  },

  created() {
    this.setAsFinalize()
    this.setUserProfile()

    console.log(this.leaderFile)
  },

  methods: {
    async sendModalData() {
      if (!this.isValidForm()) return

      const confirmAction = await this.confirmAction()

      if (!confirmAction) return

      const body = this.buildRequestBody()

      this.isLoadingSendData = true

      try {
        await axiosIns.put(`api/v1/sgq/shutdown/update/${this.data.id}`, body)

        this.updatedTable()

        this.showMessage({
          icon: 'success',
          title: 'Requisição enviada!',
        })
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.sendDocuments()
        this.isLoadingSendData = false
      }
    },

    async sendDocuments() {
      try {
        if (this.file) {
          this.sendFiles('api/v1/sgq/attachments_shutdowns_dp/submit_files', [this.file], { id: this.data.id })
            .then(() => this.clearFileField())
        }
      } catch (error) {
        this.showErrorMessage(error)
      }
    },

    async returnToLeader() {
      this.isLoadingSendLeader = true

      const body = {
        status: 'AGUARDANDO LÍDER',
      }

      try {
        await axiosIns.put(`api/v1/sgq/shutdown/update/${this.data.id}`, body)

        this.updatedTable()

        this.showMessage({
          icon: 'success',
          title: 'Requisição retornada para o líder!',
        })
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingSendLeader = false
      }
    },

    async sendRequestToFinalize() {
      this.isLoadingFinalize = true

      try {
        await axiosIns.put(`api/v1/sgq/shutdown/update/${this.data.id}`, { status: 'CONCLUIDO' })

        this.updatedTable()

        this.showMessage({
          icon: 'success',
          title: 'Requisição finalizada com sucesso!',
        })
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingFinalize = false
      }
    },

    isValidForm() {
      if (this.data.status === 'AGUARDANDO LÍDER') {
        if (!this.file) {
          this.showMessage({ title: 'Selecione um arquivo' })

          return false
        }

        return true
      }

      if (this.enableFileUpload) {
        if (!this.file) {
          this.showMessage({ title: 'Selecione um arquivo' })

          return false
        }

        return true
      }

      if (!this.isoDate) {
        this.showMessage({ title: 'Selecione uma data do ultimo dia de trabalho' })

        return false
      }

      return true
    },

    setUserProfile() {
      const humanResourcesRegex = /recursos\s+humanos/i
      const personalDepartmentRegex = /departamento\s+pessoal/i

      this.isFromHumanResources = humanResourcesRegex.test(this.userProfile)
      this.isFromPersonalDepartment = personalDepartmentRegex.test(this.userProfile)
    },

    buildRequestBody() {
      if (this.enableFileUpload) {
        return {
          status: 'AGUARDANDO LÍDER',
        }
      }

      if (this.data.status === 'AGUARDANDO LÍDER') {
        return {
          status: 'FINALIZAÇÃO COM DP',
        }
      }

      return {
        status: 'CONCLUIDO',
        Last_day_worked: this.isoDate,
      }
    },

    /**
     * Confirms an action with a warning message.
     *
     * @return {Promise<boolean>} A promise that resolves to a boolean indicating if the action was confirmed.
     */
    async confirmAction() {
      const result = await this.$swal({
        title: 'Tem certeza?',
        text: 'Ao clicar em sim, será confirmado o andamento.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })

      return result.isConfirmed
    },

    async getFile(file) {
      this.isLoadingFile = true

      const body = {
        id: file.id,
      }

      try {
        const response = await axiosIns.post('api/v1/sgq/attachments_shutdowns_dp/view_files', body, { responseType: 'blob' })
        const fileData = response.data
        const fileType = response.headers['content-type']

        this.downloadFile(fileData, fileType, file.name)
      } catch (error) {
        this.showMessage({
          icon: 'error',
          title: 'Erro ao baixar o arquivo!',
          text: error,
        })
      } finally {
        this.isLoadingFile = false
      }
    },

    /**
     * Downloads a file with the provided content, content type, and file name.
     *
     * @param {blob} fileContent - The content of the file to be downloaded
     * @param {string} typeContent - The type of the content
     * @param {string} fileName - The name of the file to be saved as
     * @return {void} No return value
     */
    downloadFile(fileContent, typeContent, fileName) {
      const file = new File([fileContent], fileName, { type: typeContent })

      saveAs(file)
    },

    getStatusClass(history) {
      if (history.status === 'CONCLUIDO') {
        return 'status-concluido'
      }

      if (history.status === 'CANCELADO') {
        return 'status-cancelado'
      }

      return history.isFinal ? 'status-atual' : 'status-concluido'
    },

    getStatusTitle(history) {
      if (history.status === 'CANCELADO') {
        return 'CANCELADO'
      }

      return this.hasConcluded || !history.isFinal ? 'CONCLUÍDO' : 'Status atual'
    },

    setAsFinalize() {
      this.hasConcluded = this.processedStatus.some(element => element.status === 'CONCLUIDO')
    },

    previousTab() {
      this.$emit('previous-tab')
    },

    async clearFileField() {
      await this.$nextTick()

      this.file = null
    },
  },
}
</script>

<style lang="scss" scoped>
.separator {
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label {
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.tag{
  background: #354546;
  color: #35ab14;
  width: 85px;
  padding: 3px;
  font-size: 10px;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px
}

.progress-container{
  position: relative;
  width: 100%;
  padding: 25px;
  padding-bottom: 0px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;
}

.progress-container-loading{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.progress-container span{
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 12px;
  font-size: 12px;
}

.item-time-line{
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;

  p{
    font-size: 12px;
    margin-left: 10px;
  }
}

.no-border {
  border-left: 1px solid transparent;
}

.item-time-line-first{
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;
  margin-bottom: -14px;

  p{
    font-size: 12px;
     margin-left: 10px;
  }
}

.container-item{
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  & + &{
    margin-top: 0px;

  }
}

.container-icon{
  display: flex;

  small{
    font-size: 12px;
    margin-left: 8px;
  }

  .status-atual {
    color: #FFB270;
  }

  .status-concluido {
    color: #39CE2F;
  }

  .status-cancelado {
    color: rgba(255, 72, 84, 1);
  }
}

.container-files {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  border: 0.2px solid rgba(202, 201, 205, 0.267);
  border-radius: 5px;
}

.document-label {
  display: flex;
  position: relative;
  top: 10px;
  left: 12px;
  z-index: 1;
  background: #312d4b;
  width: fit-content;
  font-size: 12px;
  margin-top: -10px;
}
</style>
