<template>
  <div>
    <v-card>
      <v-card-title class="pt-0 pb-0">
        <span class="title-page">Desligamento de Funcionário</span>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mt-6"
          label="Buscar por tipo de desligamento, loja, requisitante e status"
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          color="info"
          class="ml-4"
          outlined
          @click="toggleModal('create', true)"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          NOVA REQUISIÇÃO
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoadingData"
        no-data-text="Nenhum registro para exibir"
        loading-text="Carregando dados..."
      >
        <template v-slot:item.action="{ item }">
          <v-icon
            medium
            class="me-2"
            color="warning"
            @click="handleModal(item)"
          >
            {{ icons.mdiEye }}
          </v-icon>
        </template>

        <template v-slot:item.status="{ item }">
          <v-alert
            class="badge"
            :color="getStatusColor(item)"
            text
          >
            {{ item.status ? item.status : '' }}
          </v-alert>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ dateFormat(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showModalCreate"
      width="600px"
    >
      <modal-create
        @closeModalCreate="toggleModal('create', false)"
        @updatedTable="updatedTable()"
      ></modal-create>
    </v-dialog>

    <v-dialog
      v-model="showModalDetails"
      width="600px"
    >
      <modal-details
        :key="modalData.id"
        :data="modalData"
        :user-profile="getUserProfile()"
        @closeModalDetails="toggleModal('details', false)"
        @updatedTable="updatedTable()"
      ></modal-details>
    </v-dialog>

    <v-dialog
      v-model="showModalRD"
      width="700px"
    >
      <ModalRD
        :key="modalData.id"
        :data="modalData"
        :user-profile="getUserProfile()"
        @closeModalRD="toggleModal('modalRD', false)"
        @updatedTable="updatedTable(), toggleModal('modalRD', false)"
      ></ModalRD>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiClipboardText,
  mdiEye,
  mdiFilterPlusOutline,
  mdiFilterRemoveOutline,
  mdiPlaylistPlus,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalCreate from './ModalCreate.vue'
import ModalRD from './ModalRD.vue'
import ModalDetails from './tabs/ModalDetails.vue'

export default {
  components: {
    ModalRD,
    ModalCreate,
    ModalDetails,
  },

  data() {
    return {
      filterData: '',

      headers: [
        {
          text: 'REQUISITANTE',
          value: 'user_leader.name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'LOJA',
          value: 'employee.company.fantasy_name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'SETOR',
          value: 'employee.occupation_sector.sector.name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'DATA',
          value: 'created_at',
          sortable: true,
          align: 'center',
        },
        {
          text: 'FUNCIONÁRIO',
          value: 'employee.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'TIPO DE DESLIGAMENTO',
          value: 'shutdown_type',
          sortable: false,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: true,
          align: 'center',
        },
        {
          text: 'VISUALIZAR',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],

      isLoadingData: false,

      showModalRD: false,
      showModalCreate: false,
      showModalDetails: false,

      modalData: {},

      icons: {
        mdiEye,
        mdiPlaylistPlus,
        mdiClipboardText,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.getTableData()
  },

  methods: {
    async getTableData() {
      this.isLoadingData = true

      try {
        const response = await this.$http.get('/api/v1/sgq/shutdown/index')

        this.listOfFilteredItems = response.data.data
        this.itemsTable = response.data.data
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoadingData = false
      }
    },

    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      return `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`
    },

    getStatusColor(item = { status: '' }) {
      const { status } = item

      const statusColorMap = {
        'AGUARDANDO RH': 'yellowGold',
        'AGUARDANDO DP': 'blue',
        'AGUARDANDO RD': 'info',
        'AGUARDANDO DIRETORIA': 'gray',
        'AGUARDANDO CONCLUSÃO': 'greenWeak',
        'AGUARDANDO LÍDER': 'orange',
        'FINALIZAÇÃO COM DP': 'purple',
        CONCLUIDO: 'success',
        CANCELADO: 'error',
      }

      return statusColorMap[status] ?? ''
    },

    handleModal(data) {
      const { status } = data
      const modalStatusArray = [
        'CANCELADO',
        'AGUARDANDO DP',
        'AGUARDANDO RD',
        'AGUARDANDO DIRETORIA',
        'FINALIZAÇÃO COM DP',
        'AGUARDANDO LÍDER',
        'AGUARDANDO CONCLUSÃO',
        'CONCLUIDO',
      ]

      const isModalRD = modalStatusArray.includes(status.toUpperCase())
      const modalName = isModalRD ? 'modalRD' : 'details'

      this.toggleModal(modalName, true, data)
    },

    toggleModal(type, isOpen, data = {}) {
      const typeMap = {
        create: () => {
          this.showModalCreate = isOpen
        },
        details: () => {
          this.showModalDetails = isOpen
        },
        modalRD: () => {
          this.showModalRD = isOpen
        },
      }

      if (Object.keys(data).length > 0) {
        this.modalData = data
      }

      if (type in typeMap) {
        typeMap[type]()
      }
    },

    getUserProfile() {
      const userProfiles = localStorageSlim.get('userProfile', { decrypt: true })

      return userProfiles?.profile?.profile || ''
    },

    updatedTable() {
      this.getTableData()
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(
        ({
          status, employee, shutdown_type: shutdownType, user_leader: userLeader,
        }) => {
          const statusLower = status.toLowerCase()
          const shutdownTypeLower = shutdownType.toLowerCase()
          const companyNameLower = employee.company.fantasy_name.toLowerCase()
          const userLeaderNameLower = userLeader.name.toLowerCase()

          return statusLower.includes(filterDataLower)
            || shutdownTypeLower.includes(filterDataLower)
            || companyNameLower.includes(filterDataLower)
            || userLeaderNameLower.includes(filterDataLower)
        },
      )
    },
  },
}
</script>

<style lang="scss">
.badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-page {
  font-size: 15px;
}
</style>
