<template>
  <div>
    <div class="separator  my-8">
      <div
        class="label"
      >
        <span>Informações do DP</span>
      </div>
    </div>

    <div
      class="d-flex"
      style="gap: 20px;"
    >
      <v-text-field
        v-model="computedTotalVale"
        :prepend-inner-icon="icons.mdiCurrencyUsd"
        :rules="[rules.required]"
        label="Vales"
        disabled
        outlined
        dense
      />

      <v-text-field
        v-model="computedTotalDuplicate"
        label="Duplicatas"
        :prepend-inner-icon="icons.mdiCurrencyUsd"
        :rules="[rules.required]"
        disabled
        outlined
        dense
      />
    </div>

    <div
      class="d-flex align-center"
      style="gap: 40px;"
    >
      <v-radio-group
        v-model="isLoanActive"
        disabled
        mandatory
      >
        <p style="font-size: 16px">
          O funcionário tem empréstimos ativo?
        </p>

        <div
          class="d-flex align-baseline"
          style="gap: 20px;"
        >
          <v-radio
            label="Sim"
            value="SIM"
            color="#9C43FA"
          />

          <v-radio
            label="Não"
            value="NÃO"
            color="#9C43FA"
          />
        </div>
      </v-radio-group>

      <v-text-field
        v-model="computedTotalLoan"
        :prepend-inner-icon="icons.mdiCurrencyUsd"
        :rules="[rules.required]"
        label="Empréstimo Assomotoca"
        disabled
        outlined
        dense
      />
    </div>

    <div>
      <v-textarea
        v-if="data.dp_observations"
        v-model="data.dp_observations"
        class="mt-4"
        label="Observações"
        outlined
        readonly
        disabled
      />
    </div>

    <div
      v-if="showValidations"
      class="validations mb-6"
    >
      <span>Validações</span>

      <div
        v-if="isAwaitingOrFinalizationRD"
        class="infos"
      >
        <div class="image">
          <v-avatar
            size="45px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img src="../../../../../../assets/avatar-rd.svg"></v-img>
          </v-avatar>
        </div>

        <div class="infos-approved">
          <p>RD</p>

          <div class="infos-text">
            <small>{{ formattedRDDate }}</small>

            <div class="tag">
              {{ statusTagRD }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isAwaitingOrFinalizationDirector"
        class="infos mt-2"
      >
        <div class="image">
          <v-avatar
            size="45px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img src="../../../../../../assets/avatar-director.svg"></v-img>
          </v-avatar>
        </div>

        <div class="infos-approved">
          <p>Diretor</p>

          <div class="infos-text">
            <small>{{ getFormattedDirectorDate }}</small>

            <div :class="`tag ${getStatusTagDirector === 'REPROVADA'? 'reproved' : ''}`">
              {{ getStatusTagDirector }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="data.status === 'AGUARDANDO RD' || data.status === 'AGUARDANDO DIRETORIA'"
      class="d-flex justify-space-between actions-btn"
    >
      <v-btn
        outlined
        @click="$emit('previous-tab')"
      >
        <div
          class="d-flex align-center"
          style="gap: 10px;"
        >
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
          <span>
            Anterior
          </span>
        </div>
      </v-btn>

      <div class="d-flex justify-between align-items-end btns-recuse-or-aprove">
        <v-btn
          v-if="computedIsDirectorUser"
          color="error"
          class="ml-4 btn-reprove"
          :disabled="isLoadingSendData"
          @click="openModalCancel()"
        >
          <div>
            <v-icon
              medium
              class="me-2"
            >
              {{ icons.mdiThumbDownOutline }}
            </v-icon>

            <span>RECUSAR</span>
          </div>
        </v-btn>

        <v-btn
          v-if="computedIsDirectorUser"
          color="success"
          class="ml-4 btn-aprove"
          @click="sendModalData()"
        >
          <div v-if="!isLoadingSendData">
            <v-icon
              medium
              class="me-2"
            >
              {{ icons.mdiThumbUpOutline }}
            </v-icon>
            <span>APROVAR</span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="showModalCancel"
      max-width="600px"
      transition="dialog-transition"
      persistent
    >
      <modal-cancel
        :key="data.id"
        :data="data"
        @updatedTable="showModalCancel = false, closeModal(), updatedTable()"
        @closeModalCancel="showModalCancel = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import {
  mdiArrowLeft,
  mdiCurrencyUsd, mdiThumbDownOutline, mdiThumbUpOutline,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalCancel from '../ModalCancel.vue'

export default {
  components: {
    ModalCancel,
  },

  mixins: [formatters, messages, utilsMixin],

  props: {
    data: {
      type: Object,
      default: () => ({ status: '' }),
    },

    userProfile: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      file: null,
      profile: '',
      isLoanActive: 'NÃO',
      loggedUser: localStorageSlim.get('userProfile', { decrypt: true }),

      showModalCancel: false,
      isLoadingSendData: false,

      icons: {
        mdiArrowLeft,
        mdiCurrencyUsd,
        mdiThumbUpOutline,
        mdiThumbDownOutline,
      },
    }
  },

  computed: {
    isAwaitingOrFinalizationRD() {
      const { status, status_update_shutdowns: statusList } = this.data
      const containDirectorStatus = statusList.some(element => element.status === 'AGUARDANDO DIRETORIA')
      const containRDStatus = statusList.some(element => element.status === 'AGUARDANDO RD')

      if (this.RdDisapproved || (containDirectorStatus && containRDStatus)) {
        return true
      }

      if (!containDirectorStatus && status === 'CANCELADO') {
        return false
      }

      return ['CANCELADO', 'AGUARDANDO DIRETORIA', 'FINALIZAÇÃO COM DP', 'AGUARDANDO CONCLUSÃO', 'CONCLUIDO'].includes(this.data.status)
    },

    statusTagRD() {
      return !this.RdDisapproved ? 'APROVADA' : 'REPROVADA'
    },

    formattedRDDate() {
      return this.computedStatusHistory.rd
        ? this.dateFormat(this.computedStatusHistory.rd.created_at)
        : ''
    },

    isAwaitingOrFinalizationDirector() {
      if (this.directorDisapproved) {
        return true
      }

      return ['AGUARDANDO LÍDER', 'FINALIZAÇÃO COM DP', 'AGUARDANDO CONCLUSÃO', 'CONCLUIDO'].includes(this.data.status)
    },

    directorDisapproved() {
      const statuses = this.data.status_update_shutdowns || []
      const canceledIndex = statuses.findIndex(element => element.status === 'CANCELADO')
      const isDirectorDisapproved = canceledIndex !== -1 && statuses[canceledIndex - 1]?.status === 'AGUARDANDO DIRETORIA'

      return isDirectorDisapproved
    },

    RdDisapproved() {
      const statuses = this.data?.status_update_shutdowns || []
      const canceledIndex = statuses.findIndex(element => element.status === 'CANCELADO')
      const isRdDisapproved = canceledIndex !== -1 && statuses[canceledIndex - 1]?.status === 'AGUARDANDO RD'

      return isRdDisapproved
    },

    getStatusTagDirector() {
      return !this.directorDisapproved ? 'APROVADA' : 'REPROVADA'
    },

    getFormattedDirectorDate() {
      const { created_at: directorDate } = this.computedStatusHistory.director || {}

      return directorDate ? this.dateFormat(directorDate) : ''
    },

    showValidations() {
      const { status_update_shutdowns: statusList } = this.data
      const containDirectorStatus = statusList.some(element => element.status === 'AGUARDANDO DIRETORIA')
      const containRDStatus = statusList.some(element => element.status === 'AGUARDANDO RD')

      return containRDStatus && containDirectorStatus
    },

    computedTotalVale() {
      return this.formatBrazilianCurrency(this.data.total_vale) || ''
    },

    computedTotalDuplicate() {
      return this.formatBrazilianCurrency(this.data.total_duplicate) || ''
    },

    computedTotalLoan() {
      return this.formatBrazilianCurrency(this.data.total_loan) || ''
    },

    computedIsDirectorUser() {
      const directorLevels = ['nivel_3', 'nivel_4']
      const level = this.loggedUser.hierarchical_profile

      return directorLevels.includes(level)
    },

    computedStatusHistory() {
      const updates = this.data?.status_update_shutdowns || []

      const rd = updates.find(el => el.status === 'AGUARDANDO RD') || { created_at: '' }
      const director = updates.find(el => el.status === 'AGUARDANDO DIRETORIA') || { created_at: '' }

      return { rd, director }
    },
  },

  created() {
    this.isLoanActive = this.data.dp_observations ? 'SIM' : 'NÃO'
  },

  methods: {
    async sendModalData() {
      const confirmAction = await this.confirmAction()

      if (!confirmAction) return

      const status = this.data.status === 'AGUARDANDO RD' ? 'AGUARDANDO DIRETORIA' : 'FINALIZAÇÃO COM DP'

      const body = {
        status,
      }

      this.isLoadingSendData = true

      try {
        await axiosIns.put(`api/v1/sgq/shutdown/update/${this.data.id}`, body)

        this.updatedTable()
        this.showMessage({
          icon: 'success',
          title: 'Requisição aprovada!',
          text: 'Requisição aprovada com sucesso',
        })
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingSendData = false
      }
    },

    /**
     * Confirms an action with a warning message.
     *
     * @return {Promise<boolean>} A promise that resolves to a boolean indicating if the action was confirmed.
     */
    async confirmAction() {
      const result = await this.$swal({
        title: 'Tem certeza?',
        text: 'Ao clicar em sim, será confirmado o andamento.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })

      return result.isConfirmed
    },

    openModalCancel() {
      this.showModalCancel = true
    },
  },
}
</script>

<style lang="scss" scoped>
.head-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.separator {
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label {
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 25px;
}

.container-center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.validations {
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
}

.validations span {
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 8px;
}

.infos {
  display: flex;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag {
  background: #354546;
  color: #35ab14;
  width: 85px;
  padding: 3px;
  font-size: 10px;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px
}

.reproved {
  background: #dc354641;
  color: #dc3545;
}

.infos-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-container {
  position: relative;
  width: 100%;
  padding: 25px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;
}

.progress-container-loading {
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.progress-container span {
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 12px;
  font-size: 12px;
}

.item-time-line {
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;

  p {
    font-size: 12px;
    margin-left: 10px;
  }
}

.no-border {
  border-left: 1px solid transparent;
}

.item-time-line-first {
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;
  margin-bottom: -14px;

  p {
    font-size: 12px;
    margin-left: 10px;
  }
}

.container-item {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  & + & {
    margin-top: 0px;

  }
}

.item-time-line-waiting {
  padding: 10px;
  margin-left: 9px;

  p {
    font-size: 12px;
  }
}

.container-icon-waiting {
  display: flex;

  small {
    font-size: 12px;
    margin-left: 8px;
    color: #FFB270;
  }
}

.container-icon {
  display: flex;

  small {
    font-size: 12px;
    margin-left: 8px;
  }

  .status-atual {
    color: #FFB270;
  }

  .status-concluido {
    color: #39CE2F;
  }
}

.stand-by {
  display: flex;
  align-items: center;

  p {
    margin-top: 12px;
  }
}

.footer {
  display: flex;
  justify-content: center;
}

.inputs-approved {
  margin-top: 20px;
}

.wrapper-inputs {
  display: flex;
  gap: 10px;
}

.actions-finish {
  display: flex;
  align-items: center;
  justify-content: center;
}

.infos-approved {
  margin-top: 5px;
  margin-left: 10px;

  p {
    margin-bottom: 0px;
  }
}

.center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs-just {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 600px) {
  .actions-btn{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .btns-recuse-or-aprove{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .btn-aprove{
    margin-left: 0px !important;
  }

  .btn-reprove{
    margin-left: 0px !important;
  }
}
</style>
