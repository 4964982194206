<template>
  <div>
    <v-card :class="withoutHeader ? 'px-0 py-4' : 'px-6 py-6'">
      <div
        v-if="!withoutHeader"
        class="head-modal"
      >
        <div
          class="d-flex justify-center"
          style="width: 100%;"
        >
          <h4 style="font-size: 20px">
            Desligamento de Funcionário
          </h4>
        </div>

        <v-icon
          medium
          class="me-2"
          @click="closeModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div
        v-if="!withoutHeader"
        class="py-4 d-flex justify-center"
      >
        <h4 style="font-size: 15px">
          👋 Olá, para requisitar, basta preencher os campos abaixo!
        </h4>
      </div>

      <div class="separator">
        <div
          class="label"
        >
          <h4 style="font-size: 15px">
            Justificativa
          </h4>
        </div>
      </div>

      <v-text-field
        v-model="data.employee.name"
        class="pt-6"
        outlined
        disabled
        dense
      />

      <div class="line mt-0">
        <v-text-field
          v-model="data.shutdown_type"
          label="Tipo de desligamento"
          outlined
          disabled
          dense
        />

        <v-text-field
          v-model="data.notice_type"
          label="Tipo de Aviso"
          outlined
          disabled
          dense
        />

        <v-text-field
          v-model="formattedDate"
          :label="computedDateLabel"
          prepend-icon="mdi-calendar"
          disabled
          outlined
          dense
        />
      </div>

      <v-textarea
        v-model="data.reason_dismissal"
        label="Descrição do motivo de desligamento"
        outlined
        disabled
      />

      <div>
        <p>Foi realizada alguma medida preventiva?</p>

        <v-radio-group
          v-model="computedPreventiveMeasureTaken"
          disabled
          row
        >
          <v-radio
            label="Sim"
            value="SIM"
            color="#9C43FA"
          />

          <v-radio
            label="Não"
            value="NÃO"
            color="#9C43FA"
          />
        </v-radio-group>
      </div>

      <v-textarea
        v-model="data.preventive_measures"
        label="Quais?"
        outlined
        readonly
        :disabled="computedPreventiveMeasureTaken === 'NÃO'"
      />

      <div>
        <p>É necessário substituição?</p>

        <v-radio-group
          v-model="data.substitution"
          disabled
          row
        >
          <v-radio
            label="Sim"
            value="SIM"
            color="#9C43FA"
          />

          <v-radio
            label="Não"
            value="NÃO"
            color="#9C43FA"
          />

          <v-radio
            label="Indeterminado"
            value="INDETERMINADO"
            color="#9C43FA"
          />
        </v-radio-group>
      </div>

      <div
        v-if="data.status !== 'CANCELADO' && data.rh_observations"
      >
        <div
          class="separator mb-8"
        >
          <div
            class="label"
          >
            <h4 style="font-size: 15px">
              Informações do RH
            </h4>
          </div>
        </div>

        <v-textarea
          v-model="data.rh_observations"
          label="Descrição do histórico de desempenho"
          class="mt-6"
          outlined
          disabled
        />

        <div
          v-show="data.attachments_shutdowns_rh.length > 0"
          class="mb-6"
        >
          <span class="document-label px-2">
            Documentos
          </span>

          <div
            class="container-files"
          >
            <v-chip
              v-for="file in data.attachments_shutdowns_rh"
              :key="file.id"
              :disabled="isLoadingFile === file.id"
              @click="file.isLoading = true, getFile(file)"
            >
              <v-progress-circular
                v-if="isLoadingFile === file.id"
                color="white"
                size="25"
                indeterminate
              />

              <span v-else>
                {{ file.name }}
              </span>
            </v-chip>
          </div>
        </div>

        <div class="container-center">
          <v-btn
            v-if="data.status === 'AGUARDANDO DP' && isFromPersonalDepartment"
            color="success"
            width="180px"
            @click="toggleModal('sendRD', true)"
          >
            <div
              class="d-flex"
              style="gap: 5px;"
            >
              <v-img
                src="../../../../../../assets/images/svg/arrow-right.svg"
                width="20"
                max-height="20"
                class="me-2"
              />

              <span>
                Enviar para RD
              </span>
            </div>
          </v-btn>
        </div>
      </div>

      <div
        v-if="data.status === 'AGUARDANDO RH' && isFromHumanResources"
        class="container-center mt-2"
        style="gap: 35px"
      >
        <v-btn
          width="140px"
          color="error"
          @click="toggleModal('cancel', true)"
        >
          <div
            class="d-flex"
            style="gap: 10px;"
          >
            <span>
              Cancelar
            </span>

            <v-img
              src="../../../../../../assets/images/svg/cancel-circle.svg"
              width="20"
              height="20"
              class="me-2"
            />
          </div>
        </v-btn>

        <v-btn
          color="success"
          width="180px"
          @click="toggleModal('sendDP', true)"
        >
          <div
            class="d-flex"
            style="gap: 5px;"
          >
            <v-img
              src="../../../../../../assets/images/svg/arrow-right.svg"
              width="20"
              max-height="20"
              class="me-2"
            />

            <span>
              Enviar para DP
            </span>
          </div>
        </v-btn>
      </div>
    </v-card>

    <v-dialog
      v-model="showModalCancel"
      max-width="600px"
      transition="dialog-transition"
      persistent
    >
      <modal-cancel
        :key="data.id"
        :data="data"
        @updatedTable="toggleModal('cancel', false), closeModal(), updatedTable()"
        @closeModalCancel="toggleModal('cancel', false)"
      />
    </v-dialog>

    <v-dialog
      v-model="showModalDP"
      max-width="600px"
      transition="dialog-transition"
      persistent
    >
      <modal-send-DP
        :key="data.id"
        :data="data"
        @updatedTable="toggleModal('sendDP', false), closeModal(), updatedTable()"
        @closeModalDP="toggleModal('sendDP', false)"
      />
    </v-dialog>

    <v-dialog
      v-model="showModalRD"
      max-width="700px"
      transition="dialog-transition"
      persistent
    >
      <modal-send-RD
        :key="data.id"
        :data="data"
        @updatedTable="toggleModal('sendRD', false), closeModal(), updatedTable()"
        @closeModalRD="toggleModal('sendRD', false)"
      />
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiClose } from '@mdi/js'
import { saveAs } from 'file-saver'

import ModalCancel from '../ModalCancel.vue'
import ModalSendDP from '../ModalSendDP.vue'
import ModalSendRD from '../ModalSendRD.vue'

export default {
  components: {
    ModalCancel,
    ModalSendDP,
    ModalSendRD,
  },

  mixins: [messages, formatters],

  props: {
    data: {
      type: Object,
      required: true,
    },

    userProfile: {
      type: String,
      required: true,
    },

    withoutHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isFromHumanResources: false,
      isFromPersonalDepartment: false,

      isLoadingFile: null,
      showModalCancel: false,
      showModalDP: false,
      showModalRD: false,

      modalData: {},

      icons: {
        mdiClose,
      },
    }
  },

  computed: {
    computedDateLabel() {
      const labelMap = {
        DISPENSA: 'da dispensa',
        PEDIDO: 'do pedido',
        'AVISO TRABALHADO': 'do aviso',
        'AVISO INDENIZADO': 'do aviso',
        'ACORDO LEGAL': 'do acordo',
      }

      return 'Data '.concat(labelMap[this.data.shutdown_type] ?? '')
    },

    computedPreventiveMeasureTaken() {
      return this.data.preventive_measures ? 'SIM' : 'NÃO'
    },

    formattedDate() {
      const [year, month, day] = this.data.notice_date.split('-')

      return `${day}/${month}/${year}`
    },
  },

  created() {
    const humanResourcesRegex = /recursos\s+humanos/i
    const personalDepartmentRegex = /departamento\s+pessoal/i

    this.isFromHumanResources = humanResourcesRegex.test(this.userProfile)
    this.isFromPersonalDepartment = personalDepartmentRegex.test(this.userProfile)
  },

  methods: {
    async getFile(file) {
      this.isLoadingFile = file.id

      const body = {
        id: file.id,
      }

      try {
        const response = await axiosIns.post('api/v1/sgq/attachments_shutdowns_rh/view_files', body, { responseType: 'blob' })
        const fileData = response.data
        const fileType = response.headers['content-type']

        this.downloadFile(fileData, fileType, file.name)
      } catch (error) {
        this.showMessage({
          icon: 'error',
          title: 'Erro ao baixar o arquivo!',
          text: error,
        })
      } finally {
        this.isLoadingFile = false
      }
    },

    /**
     * Downloads a file with the provided content, content type, and file name.
     *
     * @param {blob} fileContent - The content of the file to be downloaded
     * @param {string} typeContent - The type of the content
     * @param {string} fileName - The name of the file to be saved as
     * @return {void} No return value
     */
    downloadFile(fileContent, typeContent, fileName) {
      const file = new File([fileContent], fileName, { type: typeContent })

      saveAs(file)
    },

    toggleModal(type, isOpen) {
      const typeMap = {
        cancel: () => { this.showModalCancel = isOpen },
        sendDP: () => { this.showModalDP = isOpen },
        sendRD: () => { this.showModalRD = isOpen },
      }

      if (type in typeMap) {
        typeMap[type]()
      }
    },

    closeModal() {
      this.$emit('closeModalDetails')
    },
  },
}
</script>

<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-center{
  display: flex;
  justify-content: center;
}

.container-files {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 10px;

  border: 0.2px solid rgba(202, 201, 205, 0.267);
  border-radius: 5px;
}

.document-label {
  display: flex;
  position: relative;
  top: 10px;
  left: 12px;
  z-index: 1;
  background: #312d4b;
  width: fit-content;
  font-size: 12px;
}
</style>
